import axios from 'axios'
import { useEffect, useState } from 'react';
import {useSearchParams} from 'react-router-dom'
// import Table from 'react-bootstrap/Table';
// import Container from 'react-bootstrap/Container';


function Mobile(){

    const [dataList, setDataList] = useState([]);
   
    
    var total = 0;

    useEffect( ()=> {
        loadData();
    },[])

    const loadData = async () => {
        await fetch("https://p.ecozzle.com/api/price")
        .then(response => response.json())
        .then(gotData => setDataList(gotData))
    }

    const displayTotal = () =>  {
        {dataList.map((row)=>{
            total = total + row.value
    })}
        
    }

    var dollarUSLocale = Intl.NumberFormat('en-US');

    function sortValue(){
        console.log('IT WORKS')
    
       let sortedByVal =  dataList.sort( function( b, a ) {
            a = a.value
            b = b.value
        
            return a < b ? -1 : a > b ? 1 : 0;
        });
    
        console.log(sortedByVal)
        setDataList(sortedByVal)

        
      }


    return(
        <div>
            {displayTotal()}
            <div>
            <p class="text-4xl font-bold text-center p-1.5 uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-30">${total.toLocaleString('en-US', {maximumFractionDigits:2})}</p>
            <br></br>
            <div class="grid w-screen place-items-center">
            <table class="w-1/4 border border-slate-300">
                <thead class="bg-gray-50 border-b-2 border-gray-200">
                    <tr>
                        <th key="id" class="p-3 text-sm font-semibold tracking-wide text-center">ID</th>
                        <th key="asset" class="p-3 text-sm font-semibold tracking-wide text-center">ASSET</th>
                        <th key="qty" class="p-3 text-sm font-semibold tracking-wide text-center">QTY</th>
                        <th key="price" class="p-3 text-sm font-semibold tracking-wide text-center">PRICE</th>
                        <th key="value" class="p-3 text-sm font-semibold tracking-wide text-center" onClick={sortValue}>VALUE</th>
                    </tr>
                </thead>
                <tbody>

                {dataList.map((row,i)=>{
                    let fraction = (row.price > 1) ? 2 : ((row.price < 1 && row.price > 0.01) ? 3 : (row.price < 0.01 && row.price > 0.001) ? 4 : (row.price < 0.001 && row.price > 0.0001) ? 5 : (row.price < 0.0001 && row.price > 0.00001) ? 6 : (row.price < 0.00001 && row.price > 0.000001) ? 7 : (row.price < 0.000001 && row.price > 0.0000001) ? 8 : (row.price < 0.0000001 && row.price > 0.00000001) ? 9 : null);
                    let fractionVal = (row.value > 1) ? 2 : ((row.value < 1 && row.value > 0.01) ? 3 : (row.value < 0.01 && row.value > 0.001) ? 4 : (row.value < 0.001 && row.value > 0.0001) ? 5 : (row.value < 0.0001 && row.value > 0.00001) ? 6 : (row.value < 0.00001 && row.value > 0.000001) ? 7 : (row.value < 0.000001 && row.value > 0.0000001) ? 8 : (row.value < 0.0000001 && row.value > 0.00000001) ? 9 : null);

                    let chartUrl = 'https://www.coingecko.com/en/coins/'+row.assetId;
                return (<tr class="bg-gray hover:bg-yellow-100 hover:font-bold" key={i}>
                    <td class="border border-slate-300 p-1 text-sm font-bold text-blue-800  text-center">{i+1}</td>
                    <td class="border border-slate-300 p-1 text-sm font-bold text-blue-800 hover:underline"><a class="text-blue-800 hover:underline" href={chartUrl} target="_blank">{row.asset}</a></td>
                    <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right">{dollarUSLocale.format(row.qty)}</td>
                    <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right">{row.price.toLocaleString('en-US', {maximumFractionDigits:fraction})}</td>
                    <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right font-medium">{row.value.toLocaleString('en-US', {maximumFractionDigits:fractionVal})}</td>
                    </tr>)
            
                 })}
{/*                 
                <tr>
                    <td class="p-1 text-sm text-gray-700 text-center font-medium" colSpan={3}>Total Value</td>
                    <td class="p-1 text-sm text-gray-700 text-right font-medium">$ {total.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                </tr> */}
                
                </tbody>
            </table>
            </div>
            </div>

        </div>

        
    );
    
  
  }

  

  export default Mobile