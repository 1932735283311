import axios from 'axios'
import { useEffect, useState } from 'react';
import {useSearchParams} from 'react-router-dom'

function Home(){

    const [searchParams,setSearchParams]=useSearchParams();
    let coin = searchParams.get('c')
    
    const url = "https://www.binance.com/api/v3/ticker/price?symbol="+coin;
    const [data, setData]=useState(null);
  
    useEffect(()=>{
      axios.get(url)
      .then(response =>{
          setData(response.data)
      })
    },[])
    
    if(data){

        return (
            <div className="App">
               <h1>{data.symbol}</h1>
               <h1>${data.price}</h1>
               <h2>CEX</h2>
            </div>
          );

    }

    else{

        return (
            <div className="App">
               <h2>CEX</h2>
            </div>
          );
    }
    
  
  }

  export default Home