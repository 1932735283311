import axios from 'axios'
import { useEffect, useState } from 'react';
import {useSearchParams} from 'react-router-dom'
// import Table from 'react-bootstrap/Table';
// import Container from 'react-bootstrap/Container';


function Db(){

    const [dataList, setDataList] = useState([]);
   
    
    var total = 0;

    useEffect( ()=> {
        loadData();
    },[])

    const loadData = async () => {
        await fetch("https://p.ecozzle.com/api/price")
        .then(response => response.json())
        .then(gotData => setDataList(gotData))
    }

    const displayTotal = () =>  {
        {dataList.map((row)=>{
            total = total + row.value
    })}
        
    }

    var dollarUSLocale = Intl.NumberFormat('en-US');

    function sortValue(){
        console.log('IT WORKS')
    
       let sortedByVal =  dataList.sort( function( b, a ) {
            a = a.value
            b = b.value
        
            return a < b ? -1 : a > b ? 1 : 0;
        });
    
        console.log(sortedByVal)
        setDataList(sortedByVal)

        
      }

      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.coingecko.com/coingecko-coin-price-chart-widget.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.coingecko.com/coingecko-coin-ticker-widget.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.coingecko.com/coingecko-coin-converter-widget.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);




      const[geckoID,setGeckoID] = useState("bitcoin")

      function showChart(id){
        setGeckoID(id);
      }

      function resetChart(){
        setGeckoID('bitcoin');
        
      }

    return(
        <div>
            {displayTotal()}
            <div>
            <p class="w-1/4  text-4xl font-bold text-right p-1.5 uppercase tracking-wider text-green-800 rounded-lg bg-opacity-100" onclick={() => resetChart}>${total.toLocaleString('en-US', {maximumFractionDigits:2})}</p>
         
                <div class="grid grid-cols-2 gap-1">
                    <div class="m-5 ">
                        <table class="w-1/2 border border-slate-300">
                            <thead class="bg-gray-50 border border-b-2 border-slate-300">
                                <tr class="sticky top-0  bg-white">
                                    <th key="id" class="border border-b-2 border-slate-300 p-3 text-sm font-semibold tracking-wide text-center">ID</th>
                                    <th key="asset" class="border border-b-2 border-slate-300 p-3 text-sm font-semibold tracking-wide text-center">ASSET</th>
                                    <th key="qty" class="border border-b-2 border-slate-300 p-3 text-sm font-semibold tracking-wide text-center">QTY</th>
                                    <th key="price" class="border border-b-2 border-slate-300 p-3 text-sm font-semibold tracking-wide text-center">PRICE</th>
                                    <th key="value" class="border border-b-2 border-slate-300 p-3 text-sm font-semibold tracking-wide text-center" onClick={sortValue}>VALUE</th>
                                </tr>
                            </thead>
                            <tbody>

                            {dataList.map((row,i)=>{
                                let fraction = (row.price > 1) ? 2 : ((row.price < 1 && row.price > 0.01) ? 3 : (row.price < 0.01 && row.price > 0.001) ? 4 : (row.price < 0.001 && row.price > 0.0001) ? 5 : (row.price < 0.0001 && row.price > 0.00001) ? 6 : (row.price < 0.00001 && row.price > 0.000001) ? 7 : (row.price < 0.000001 && row.price > 0.0000001) ? 8 : (row.price < 0.0000001 && row.price > 0.00000001) ? 9 : null);
                                let fractionVal = (row.value > 1) ? 2 : ((row.value < 1 && row.value > 0.01) ? 3 : (row.value < 0.01 && row.value > 0.001) ? 4 : (row.value < 0.001 && row.value > 0.0001) ? 5 : (row.value < 0.0001 && row.value > 0.00001) ? 6 : (row.value < 0.00001 && row.value > 0.000001) ? 7 : (row.value < 0.000001 && row.value > 0.0000001) ? 8 : (row.value < 0.0000001 && row.value > 0.00000001) ? 9 : null);

                                let chartUrl = 'https://www.coingecko.com/en/coins/'+row.assetId;
                            return (<tr class="bg-gray hover:bg-yellow-100 hover:font-bold" key={i}>
                                <td class="border border-slate-300 p-1 text-sm font-bold text-blue-800  text-center">{i+1}</td>
                                <td class="border border-slate-300 p-1 text-sm font-bold text-blue-800 hover:underline" onClick={() => showChart(row.assetId)}><a class="text-blue-800 hover:underline" href="#" >{row.asset}</a></td>
                                <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right">{dollarUSLocale.format(row.qty)}</td>
                                <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right">{row.price.toLocaleString('en-US', {maximumFractionDigits:fraction})}</td>
                                <td class="border border-slate-300 p-1 text-sm text-gray-700 text-right font-medium">{row.value.toLocaleString('en-US', {maximumFractionDigits:fractionVal})}</td>
                                </tr>)
                        
                            })}
            {/*                 
                            <tr>
                                <td class="p-1 text-sm text-gray-700 text-center font-medium" colSpan={3}>Total Value</td>
                                <td class="p-1 text-sm text-gray-700 text-right font-medium">$ {total.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                            </tr> */}
                            
                            </tbody>
                        </table>
                    </div>
                    <div class="m-10 text-left fixed bottom-17 left-50 right-0">
                        <coingecko-coin-ticker-widget  coin-id={geckoID} currency="usd" locale="en" background-color="#ffffff"></coingecko-coin-ticker-widget>
                        <br></br>
                        <div class="grid grid-cols-2 gap-1">
                            <div>
                                <coingecko-coin-price-chart-widget  coin-id = {geckoID} currency="usd" height="300" width="480" locale="en"></coingecko-coin-price-chart-widget>
                            </div>
                            <div>
                                <coingecko-coin-converter-widget  coin-id={geckoID} currency="usd" height="300" background-color="#ffffff" font-color="#4c4c4c" locale="en"></coingecko-coin-converter-widget>
                                {dataList.map((row,i)=>{
                                    let fractionVal = (row.value > 1) ? 2 : ((row.value < 1 && row.value > 0.01) ? 3 : (row.value < 0.01 && row.value > 0.001) ? 4 : (row.value < 0.001 && row.value > 0.0001) ? 5 : (row.value < 0.0001 && row.value > 0.00001) ? 6 : (row.value < 0.00001 && row.value > 0.000001) ? 7 : (row.value < 0.000001 && row.value > 0.0000001) ? 8 : (row.value < 0.0000001 && row.value > 0.00000001) ? 9 : null);

                                    if(row.assetId == geckoID){
                                        return (
                                            <p class="text-xl p-2 mt-5 font-semibold" >You Have {row.qty.toLocaleString('en-US', {maximumFractionDigits:2})} {row.asset} valued at ${row.value.toLocaleString('en-US', {maximumFractionDigits:fractionVal})}</p>
                                        )
                                    }
                                    else{

                                    }
                                    })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        
    );
    
  
  }

  

  export default Db