import './App.css';
import Home from './cex.js'
import Db from './db.js'
import Mobile from './mobile.js'

// import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import {useSearchParams} from 'react-router-dom' 
import {BrowserRouter, Route, Routes} from 'react-router-dom'



function App() {



  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Db></Db>}></Route>
            <Route path="/mobile" element={<Mobile></Mobile>}></Route>
            <Route path="/cex" element={<Home></Home>}></Route>
            {/* <Route path="/db" element={<Db></Db>}></Route> */}
          </Routes>
        </BrowserRouter>
    </div>
  );
}

function Test(){
  return(
    <div className='App'>
      <h1 className="text-3xl text-red-500 font-bold underline">
      Hello world!
    </h1>
    </div>
  );
}




export default App;